import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/filmography',
    name: 'Filmography',
    meta: {
      auth: false,
      title: 'Vlad Klimchuk | Ukrainian Highest Grossing Box Office Film Director',
      description: 'Ukrainian Highest Grossing Box Office Film Director',
    },
   // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      auth: false,
      title: 'Biography | Vlad Klimchuk',
      description: 'Vlad Klimchuk is Ukrainian Highest Grossing Box Office Film Director. His feature films CRAZY WEDDING (2018) and CRAZY WEDDING 2 (2020) have each been the Top-Grossing Films in his home country.',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/',
    name: 'commercials',
    meta: {
      auth: false,
      title: 'Commercials | Vlad Klimchuk',
      description: 'Vlad Klimchuk is Ukrainian Highest Grossing Box Office Film Director. His feature films CRAZY WEDDING (2018) and CRAZY WEDDING 2 (2020) have each been the Top-Grossing Films in his home country.',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "commercials" */ '../views/CommercialsView.vue')
  },
   {
    path: '/films',
    name: 'film',
    meta: {
      auth: false,
      title: 'Film | Vlad Klimchuk',
      description: ' Feature films CRAZY WEDDING (2018) and CRAZY WEDDING 2 (2020) have each been the Top-Grossing Films in his home country.',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Filmography.vue')
  },
   {
    path: '/contact',
    name: 'Contact',
    meta: {
      auth: false,
      title: 'Contact  | Vlad Klimchuk',
      description: '',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  { path: "/:pathMatch(.*)*",
    name: '404',
    meta: {
      auth: false,
      title: 'Page Not Found  | Vlad Klimchuk',
      description: '',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PageNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: "history",
  scrollBehavior(to, from, next, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if(to.hash){
      let elem = document.querySelector(`${to.hash}`)
      window.setTimeout(function(){
         elem.scrollIntoView({behavior: "smooth"})
      }, 500);
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }

})

export default router

// router.beforeEach((to,from, next) => {
//     if(to.path == '/about') {
//       next('/')
//     } else {
//       next()
//     }
// })

<template>
	<header :class="{film : routerName === 'Filmography'}">
		<div class="header-wrap">
			<div class="logo">
				<router-link to="/">
					<img src="../assets/images/logo.svg" alt="VLAD KLIMCHUK" width="312" height="27">
					<!-- <p>Film Director</p> -->
				</router-link>
			</div>
			<div class="main-menu">
				<div class="burger-wrap">
					<div class="burger-nav" @click="showMobileMenu">
					  <span></span>
					  <span></span>
					  <span></span>
					  <span></span>
					</div>
				</div>
				 <transition name="fade">
					<nav :class="{active : showMobNav}">
						 <div class="burger-close" @click="showMobileMenu">
			                <span></span>
			                <span></span>
			             </div>
						<ul>
							<li><router-link to="/">Commercials</router-link></li>
							<li><router-link to="/filmography">Films</router-link></li>
							<li><router-link to="/about">About</router-link></li>
							<li><router-link to="/contact">Contact</router-link></li>
						</ul>
					</nav>
				</transition>
				 <transition name="bg-nav">
					<div class="bg-nav" v-if="showMobNav" @click="showMobileMenu"></div>
				</transition>
			</div>
		</div>
	</header>
</template>
<script>
	export default {
		data() {
			return {
				showMobNav: false,
				routerName: ''
			}
		},
		watch: {
			'$route' (to) {
				this.showMobNav = false
				document.querySelector('html').style.overflow='hidden auto'
        document.querySelector('body').style.overflow='hidden auto'

				this.routerName = to.name
			}
		},
		mounted() {
			this.currentRouter()
		},
		methods: {
	      isMobileSize: function(){
			this.isMobile = this.windowSize < 1080 ? false : true

			this.showNavbar = this.windowSize < 1080 ? true : ''
			},
			showMobileMenu(){
				if(this.showMobNav){
				  this.showMobNav = false
				  document.querySelector('html').style.overflow='hidden auto'
				}
				else {
				  this.showMobNav = true
				  document.querySelector('html').style.overflow='hidden'
				}
			},
			currentRouter() {
				this.routerName = this.$route.name
			}
		}
	}
</script>
